import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import LoginLog from "@/view/pages/user/detail/LoginLog";
import PasswordUpdate from "@/view/pages/user/detail/PasswordUpdate";
import RecentActivity from "@/view/pages/user/detail/RecentActivity";
import { GET } from "@/core/services/store/request.module";
import JwtService from "@/core/services/jwt.service";
export default {
  name: "basic-profile",
  data: function data() {
    return {
      userId: 0,
      userTab: null,
      pageLoading: true,
      currentUser: new Object(),
      userData: new Object(),
      loginLogs: [],
      tabs: [{
        title: "Recent Activities",
        icon: "mdi-credit-card",
        key: "history",
        disabled: false
      }, {
        title: "Login Log",
        icon: "mdi-credit-card",
        key: "login-log",
        disabled: false
      }, {
        title: "Password",
        icon: "mdi-credit-card",
        key: "password",
        disabled: false
      }]
    };
  },
  components: {
    LoginLog: LoginLog,
    PasswordUpdate: PasswordUpdate,
    RecentActivity: RecentActivity
  },
  methods: {
    logoutUser: function logoutUser() {
      JwtService.destroyAuth();
    },
    getUserProfile: function getUserProfile() {
      var _this = this;

      try {
        _this.currentUser = JwtService.currentUser();
        _this.userId = _this.currentUser.id;
      } catch (error) {
        _this.logError(error);
      }
    },
    getUser: function getUser() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(GET, {
            url: "user/" + _this.userId
          }).then(function (response) {
            resolve(response.data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    loadContent: function loadContent() {
      var _this = this;

      _this.pageLoading = true;

      _this.getUser().then(function (response) {
        _this.userData = response;
        _this.loginLogs = response.login_details || [];
      }).catch(function (error) {
        _this.logError(error);

        _this.$router.go(-1);
      }).finally(function () {
        _this.pageLoading = false;
      });
    }
  },
  mounted: function mounted() {
    this.getUserProfile();
    this.loadContent();
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Profile"
    }, {
      title: "Basic"
    }]);
  },
  computed: {
    getProfileImage: function getProfileImage() {
      if (this.lodash.isEmpty(this.currentUser) === false && this.lodash.isEmpty(this.currentUser.profile_logo) === false) {
        return this.currentUser.profile_logo.file.url;
      }

      return null;
    }
  }
};